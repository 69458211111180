import React from "react";
import "./TheFooter.scss";
import { Container } from "@mui/material";

const TheFooter = () => {
  return (
    <div style={{ backgroundColor: "black" }} className="footer-wrapper">
      <Container maxWidth="xl" className="container-wrapper">
        <p
          style={{
            textAlign: "center",
            fontSize: "12px",
            color: "white",
            padding: "12px",
            marginTop: "12px",
          }}
        >
          Kien Hung Architecture -T: 093 865 15 61 - E: kienhung@gmail.com - W:
          www.ktkh.vn
        </p>
      </Container>
    </div>
  );
};

export default React.memo(TheFooter);
