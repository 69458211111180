import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ManagerHeader from "./ManagerHeader";
import ManagerSideBar from "./ManagerSideBar";
import "./Manager.scss";
import { Box, CircularProgress, Container } from "@mui/material";
import { auth } from "../../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function ManagerContent(props) {
  const [user, loading, error] = useAuthState(auth);

  if (!loading && !user) return (<Navigate to={'/login'}/>)

  if (loading) return (
    <Box
      sx={{
        minHeight: "calc(100vh)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="warning" variant="indeterminate" />
    </Box>
  )
  return (
    <div className="manager-wrapper">
      <ManagerSideBar />
      <div className="manager-content-wrapper">
        <ManagerHeader />
        <Container className="content-of-manager" maxWidth="xl">
          <Outlet />
        </Container>
      </div>
    </div>
  );
}

export default ManagerContent;
