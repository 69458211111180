import { Route, Routes, Navigate } from "react-router-dom";
import routes from "../routes";
import React from "react";
import ManagerContent from "../views/layout-components/admin-layout/ManagerContent";
import TheContent from "../views/layout-components/main-layout/TheContent";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const Loading = () => {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="warning" variant="indeterminate" />
    </Box>
  );
};

const Routers = () => {
  return (
    <React.Suspense fallback={Loading}>
      <Routes>
        <Route path="/admin" name="Manage" element={<ManagerContent />}>
          {routes.managerRoute?.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              )
            );
          })}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
        <Route path="/" name="Home" element={<TheContent />}>
          <Route path="/" element={<Navigate to="/project" replace />} />
          {routes.commonRoute.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              )
            );
          })}
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  );
};

export default Routers;
