// // import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth } from "../../../services/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
function Login(props) {
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: { email: "", password: "" },
  });
  const [user, loading, error] = useAuthState(auth);

  const location = useLocation();
  const validateData = () => {
    const errors = { email: "", password: "" };
    const isEmail = String(state.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    const isPassword = state.password.length >= 6;
    if (!isEmail) errors.email = "Email không hợp lệ";
    if (!isPassword) errors.password = "Password ít nhất 6 kí tự";
    return { isValid: isEmail && isPassword, errors };
  };

  const handleOnChange = (name, value) => {
    setState((state) => {
      return { ...state, [name]: value };
    });
  };

  const onSubmit = () => {
    const validation = validateData();
    if (!validation.isValid) {
      handleOnChange("errors", validation.errors);
      return;
    }
    handleOnChange("errors", {});
    signInWithEmailAndPassword(auth, state.email, state.password)
      .then((userCredential) => {})
      .catch((error) => {
        handleOnChange("errors", { password: error.message });
      });
    console.log({ state });
  };

  if (user)
    return <Navigate to="/admin/home" state={{ from: location }} replace />;

  if (loading)
    return (
      <Box
        sx={{
          minHeight: "calc(100vh - 340px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="warning" variant="indeterminate" />
      </Box>
    );
  return (
    <Box sx={{ margin: "48px auto", alignItems: "center" }}>
      <h1 style={{ color: "white" }}>
        Bạn cần đăng nhập để tiếp tục sử dụng dịch vụ {user?.email}
      </h1>
      <Box sx={{ marginTop: "24px" }} className="input-field">
        <label style={{ color: "white" }} htmlFor="email">
          Email
        </label>
        <input
          onChange={(e) => handleOnChange("email", e.target.value)}
          value={state.email}
          type={"text"}
        />
        {state.errors.email && (
          <span
            style={{
              display: "inline-block",
              margin: "12px 9",
              fontSize: "12px",
              color: "#c62222",
            }}
          >
            {state.errors.email}
          </span>
        )}
      </Box>
      <Box className="input-field">
        <label style={{ color: "white" }} htmlFor="password">
          Password
        </label>
        <input
          onChange={(e) => handleOnChange("password", e.target.value)}
          value={state.password}
          type={"password"}
        />
        {state.errors.password && (
          <span
            style={{
              display: "inline-block",
              margin: "12px 9",
              fontSize: "12px",
              color: "#c62222",
            }}
          >
            {state.errors.password}
          </span>
        )}
      </Box>
      <Button onClick={onSubmit} variant="contained">
        Đăng nhập
      </Button>
    </Box>
  );
}

export default Login;
