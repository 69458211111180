import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routers";
import ConfirmActionModal from "./views/component/ConfirmActionModal";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ConfirmActionModal />
        <Routers />
        {/* <ToastComponent></ToastComponent> */}
      </BrowserRouter>
    );
  }
}

export default App;
