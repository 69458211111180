import React from "react";
import { Outlet } from "react-router-dom";

import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import { Container } from "@mui/material";

const TheContent = () => {
  return (
    <div
      style={{
        background: "black",
        minHeight: "100vh",
        overflow: 'hidden'
      }}
    >
      <Container maxWidth='lg'>
        <TheHeader></TheHeader>

        <Outlet></Outlet>
        <TheFooter></TheFooter>
      </Container>
    </div>
  );
};

export default React.memo(TheContent);
