import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./TheHeader.scss";
import { NavLink, useNavigate } from "react-router-dom";

const TheHeader = () => {
  const [anchorElNav, setAnchorElNav] = useState();
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuItemOnClick = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  const pages = [
    { label: "GIỚI THIỆU", to: "/home" },
    { label: "DỰ ÁN", to: "/project" },
    { label: "THI CÔNG", to: "/construction" },
    { label: "BẢNG GIÁ", to: "/price" },
    { label: "LIÊN HỆ", to: "/contact" },
  ];

  return (
    <AppBar
      className="header-wrapper"
      sx={{ backgroundColor: "black" }}
      position="static"
    >
      <Container maxWidth="lg">
        <Container
          className="top-bar"
          sx={{ display: { xs: "flex" }, justifyContent: "end" }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box className="languge-choices" sx={{ display: { xs: "flex" } }}>
            <span className="laguage-type">EN</span>
            <span className="laguage-type">VN</span>
          </Box>
        </Container>
        <Container
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "left",
            }}
          >
            <img width={'250px'} src="/logo-KIENHUNG-GIFF.gif" alt="" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages?.map((page) => (
                <NavLink
                  key={page.label}
                  className="nav-item"
                  style={{
                    display: "block",
                    padding: "8px 18px",
                    textDecoration: "none",
                    color: "black",
                  }}
                  onClick={() => menuItemOnClick(page.to)}
                  to={page.to}
                  role="button"
                >
                  {page.label}
                </NavLink>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <img width={'250px'} src="/logo-KIENHUNG-GIFF.gif" alt="" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            {pages.map((page) => (
              <NavLink
                key={page.label}
                className="nav-item"
                to={page.to}
                role="button"
              >
                {page.label}
              </NavLink>
            ))}
          </Box>
        </Container>
      </Container>
    </AppBar>
  );
};

export default React.memo(TheHeader);
