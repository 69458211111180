import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  AiOutlineDashboard,
  AiFillProject,
  AiOutlineContacts,
} from "react-icons/ai";
import { GiExplosiveMaterials } from "react-icons/gi";
import { MdHomeWork } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { signOut } from "firebase/auth";
import { auth } from "../../../services/firebase";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./ManagerSideBar.scss";
import { LogoutOutlined } from "@mui/icons-material";
function TheNavigation() {
  const navigate = useNavigate()
  const menuList = [
    {
      name: "Trang chủ",
      icon: <AiOutlineDashboard />,
      link: "/admin/home",
    },
    {
      name: "Dự án",
      icon: <AiFillProject />,
      link: "/admin/projects",
    },
    // {
    //   name: "Vật liệu",
    //   icon: <GiExplosiveMaterials />,
    //   link: "/admin/materials",
    // },
    {
      name: "Thi công",
      icon: <MdHomeWork />,
      link: "/admin/works",
    },
    {
      name: "Bảng giá",
      icon: <RiMoneyDollarCircleLine />,
      link: "/admin/price",
    },
    {
      name: "Liên hệ",
      icon: <AiOutlineContacts />,
      link: "/admin/contact",
    },
  ];
  return (
    <>
      <div className="manager-side-bar-wrapper">
        <div className="mystery-box"></div>
        <div
          className="fixed-position-content"

        >
          <div className="branding-heading">
            <h1
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/trang-chu");
              }}
            >
              KienHung manager
            </h1>
          </div>
          <List className="manager-side-bar">
            {menuList.map((item, index) => (
              <NavLink
                style={({ isActive }) =>
                  isActive ? { backgroundColor: "#3c3c3c" } : undefined
                }
                to={item.link}
                key={index}
              >
                <ListItem className="manager-nav-item" sx={{ gutters: "24px" }}>
                  <ListItemIcon className="manager-nav-leading">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className="manager-nav-content"
                    primary={item.name}
                  />
                </ListItem>
              </NavLink>
            ))}
          </List>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button onClick={()=>{
              signOut(auth).then(() => {
                navigate('/home')
              }).catch((error) => {
                // An error happened.
              });
            }} variant="contained">
              <ListItem className="manager-nav-item" sx={{ gutters: "8px" }}>
                <ListItemIcon className="manager-nav-leading">
                  <LogoutOutlined />
                </ListItemIcon>
                <ListItemText
                  className="manager-nav-content"
                  primary={"Đăng xuất"}
                />
              </ListItem>
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
}
export default React.memo(TheNavigation);
