import React from "react";
import Login from "./views/pages/auth/Login";

const Homepage = React.lazy(() => import("./views/pages/home/Home"));
const ProjectPage = React.lazy(() => import("./views/pages/project/Project.js"));
const WorkPage = React.lazy(() => import("./views/pages/work/Work.js"));
const PricePage = React.lazy(() => import("./views/pages/price/Price.js"));
const Album = React.lazy(() => import("./views/pages/album/Album"));
const Items = React.lazy(() => import("./views/pages/items/Items"));
const Contact = React.lazy(() => import("./views/pages/contact/Contact"));
const LoginPage = React.lazy(() => import("./views/pages/auth/Login"));



const AdminPage = React.lazy(()=>import("./views/pages/admin"))
const HomeManager = React.lazy(()=>import("./views/pages/admin/home/HomeManager"))
const ProjectManager = React.lazy(()=>import("./views/pages/admin/projects/ProjectManager"))
const NewProjectPage = React.lazy(()=>import("./views/pages/admin/projects/NewProject"))
const EditProjectPage = React.lazy(()=>import("./views/pages/admin/projects/EditProject"))
const ProjectDetailPage = React.lazy(()=>import("./views/pages/admin/projects/ProjectDetail"))


const WorkManager = React.lazy(()=>import("./views/pages/admin/works/WorkManager"))
const NewWorkPage = React.lazy(()=>import("./views/pages/admin/works/NewWork"))
const EditWorkPage = React.lazy(()=>import("./views/pages/admin/works/EditWork"))
const WorkDetailPage = React.lazy(()=>import("./views/pages/admin/works/WorkDetail"))



const ContactManager = React.lazy(()=>import("./views/pages/admin/contact/ContactManager"))
const MaterialManager = React.lazy(()=>import("./views/pages/admin/materials/MaterialManager"))
const PriceManager = React.lazy(()=>import("./views/pages/admin/price/PriceManager"))
const NewPricePage = React.lazy(()=>import("./views/pages/admin/price/NewPrice"))
const EditPricePage = React.lazy(()=>import("./views/pages/admin/price/EditPrice"))
const PriceDetailPage = React.lazy(()=>import("./views/pages/admin/price/PriceDetail"))


const publicRoute = [];

const commonRoute = [
  { path: "/home", name: "Home", element: <Homepage /> },
  { path: "/contact", name: "Home", element: <Contact /> },
  { path: "/about", name: "Home", element: <Homepage /> },
  { path: "/project", name: "Home", element: <ProjectPage /> },
  { path: "/furniture", name: "Home", element: <Items /> },
  { path: "/construction", name: "Home", element: <WorkPage /> },
  { path: "/price", name: "Home", element: <PricePage /> },
  { path: "/album/:page/:id", name: "Home", element: <Album /> },
  { path: "/login", name: "Login", element: <Login /> },
];

const protectedRoute = [];

const managerRoute = [
  {
    path: "/admin/home",
    name: "Dashboard",
    element: <HomeManager />,
  },
  {
    path: "/admin/projects",
    name: "Dashboard",
    element: <ProjectManager />,
  },

  {
    path: "/admin/projects/:id",
    name: "Dashboard",
    element: <ProjectDetailPage/>,
  },
  {
    path: "/admin/projects/new",
    name: "Dashboard",
    element: <NewProjectPage/>,
  },

  {
    path: "/admin/projects/edit/:id",
    name: "Edit Project",
    element: <EditProjectPage/>,
  },

  {
    path: "/admin/works",
    name: "Dashboard",
    element: <WorkManager />,
  },

  {
    path: "/admin/works/:id",
    name: "Dashboard",
    element: <WorkDetailPage/>,
  },
  {
    path: "/admin/works/new",
    name: "Dashboard",
    element: <NewWorkPage/>,
  },

  {
    path: "/admin/works/edit/:id",
    name: "Edit Work",
    element: <EditWorkPage/>,
  },
  // {
  //   path: "/admin/materials",
  //   name: "Dashboard",
  //   element: <MaterialManager />,
  // },
  {
    path: "/admin/home",
    name: "Dashboard",
    element: <AdminPage />,
  },
  {
    path: "/admin/price",
    name: "Dashboard",
    element: <PriceManager />,
  },
  {
    path: "/admin/price/new",
    name: "Dashboard",
    element: <NewPricePage />,
  },
  {
    path: "/admin/price/:id",
    name: "Dashboard",
    element: <PriceDetailPage />,
  },
  {
    path: "/admin/price/edit/:id",
    name: "Edit Price Table",
    element: <EditPricePage/>,
  },
  {
    path: "/admin/contact",
    name: "Dashboard",
    element: <ContactManager />,
  },
];

const routes = {commonRoute, managerRoute, protectedRoute, publicRoute};

export default routes;
